import React, { useState, useRef } from "react";
import "./Services.scss"; // Import the SCSS file for styling

const services = [
  {
    id: 1,
    title: "City Tours",
    description:
      "Explore the city like never before with our guided city tours. Discover hidden gems, iconic landmarks, and immerse yourself in the rich culture and history that the city has to offer.",
    image: require("../../assets/pictures/915MOVEMENT_013.webp"),
  },
  {
    id: 2,
    title: "Event Packages",
    description:
      "Planning a special event? Our all-inclusive event packages cover everything from transportation to venue arrangements. We ensure an unforgettable experience tailored to your needs.",
    image: require("../../assets/pictures/915MOVEMENT_006.webp"),
  },
  {
    id: 3,
    title: "VIP Experience",
    description:
      "Experience the ultimate in luxury with our VIP packages. From exclusive access to premium services to personal chauffeurs, our VIP experience ensures you feel like a star.",
    image: require("../../assets/pictures/915MOVEMENT_011.webp"),
  },
  {
    id: 4,
    title: "Night Out",
    description:
      "Enjoy a night out in style with our dedicated service. Whether it’s a date night or a night on the town with friends, we provide top-notch service to keep the night young and exciting.",
    image: require("../../assets/pictures/915MOVEMENT_014.webp"),
  },
];

const Services = () => {
  const [activeService, setActiveService] = useState(null); // State to track the active service for modal display
  const [isHovered, setIsHovered] = useState(false); // State to track if a card is hovered

  const handleCardClick = (service) => {
    setActiveService(service); // Set the clicked service as active to display in the modal
  };

  const handleClose = () => {
    setActiveService(null); // Close the modal and reset state
  };

  return (
    <div id="servicesSection" className="services-container">
      <h1 className={`services-title ${isHovered ? 'fade-out' : ''}`}>Services</h1>

      {services.map((service) => (
        <button
          key={service.id}
          className={`service-card ${activeService && activeService.id === service.id ? "expanded" : ""}`}
          onClick={() => handleCardClick(service)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          tabIndex={0}
          style={{ backgroundImage: `url(${service.image})` }}
          aria-label={service.title}
        >
          <div className="service-overlay">
            <h2 className="service-name">{service.title}</h2>
          </div>
        </button>
      ))}

      {activeService && (
        <div
          className={`modal-overlay ${activeService ? "active" : ""}`}
          onClick={handleClose}
          style={{ backgroundImage: `url(${activeService.image})` }}
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-modal-x" onClick={handleClose}>
              &times;
            </span>
            <h2>{activeService.title}</h2>
            <p>{activeService.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
