import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing meta tags
import styles from "./Footer.module.scss";
import logo from "../../Logo1.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import phone from "../../assets/phone.svg";

const Footer = () => {
  // Get the current year dynamically
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Helmet>
        <title>NINEONEFIVEMOVEMENT - 915MVMT</title>
        <meta
          name="description"
          content="Connect with 915Movement through our contact options including phone, Facebook, and Instagram. We provide the best mobile party experience in El Paso and Las Cruces."
        />
        <meta
          name="keywords"
          content="915Movement, contact, social media, Facebook, Instagram, phone, El Paso, mobile party, 915 MVMT, footer"
        />
        <meta name="author" content="915Movement" />
        <meta property="og:title" content="Footer | 915Movement" />
        <meta
          property="og:description"
          content="Stay connected with 915Movement. Reach us by phone or follow us on Facebook and Instagram for updates on our exclusive mobile party experiences."
        />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://915mvmt.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <footer className={styles.footerWrapper}>
        <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <img src={logo} alt="Logo" className={styles.logo} />
          </div>
          <div className={styles.iconsContainer}>
            <a href="tel:+19152075261" className={styles.iconLink}>
              <img src={phone} alt="Phone" className={styles.icon} />
            </a>
            <a
              href="https://www.facebook.com/p/915-MVMT-100074594611338/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.iconLink}
            >
              <img src={facebook} alt="Facebook" className={styles.icon} />
            </a>
            <a
              href="https://www.instagram.com/915_mvmt_/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.iconLink}
            >
              <img src={instagram} alt="Instagram" className={styles.icon} />
            </a>
          </div>
          <div className={styles.copyright}>
            © {currentYear} 915MVMT LLC. All rights reserved.
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
