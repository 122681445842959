import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styles from "./Gallery.module.scss";

// Import all images directly
import image1 from "../../assets/pictures/915MOVEMENT_001.webp";
import image2 from "../../assets/pictures/915MOVEMENT_002.webp";
import image4 from "../../assets/pictures/915MOVEMENT_004.webp";
import image5 from "../../assets/pictures/915MOVEMENT_005.webp";
import image6 from "../../assets/pictures/915MOVEMENT_006.webp";
import image9 from "../../assets/pictures/915MOVEMENT_009.webp";
import image10 from "../../assets/pictures/915MOVEMENT_010.webp";
import image11 from "../../assets/pictures/915MOVEMENT_011.webp";
import image13 from "../../assets/pictures/915MOVEMENT_013.webp";
import image14 from "../../assets/pictures/915MOVEMENT_014.webp";
import image16 from "../../assets/pictures/915MOVEMENT_016.webp";

// List of YouTube video URLs with loop, autoplay, and mute parameters
const videos = [
  "https://www.youtube.com/embed/Zux5vKrmsAY?autoplay=1&controls=0&mute=1&loop=1&playlist=Zux5vKrmsAY&modestbranding=1&rel=0&showinfo=0",
  "https://www.youtube.com/embed/erJBpUA9YHE?autoplay=1&controls=0&mute=1&loop=1&playlist=erJBpUA9YHE&modestbranding=1&rel=0&showinfo=0",
  "https://www.youtube.com/embed/CnFlqvtD5Iw?autoplay=1&controls=0&mute=1&loop=1&playlist=CnFlqvtD5Iw&modestbranding=1&rel=0&showinfo=0",
  "https://www.youtube.com/embed/86mkdYWvc0w?autoplay=1&controls=0&mute=1&loop=1&playlist=86mkdYWvc0w&modestbranding=1&rel=0&showinfo=0"
];

const Gallery = () => {
  const [activeMedia, setActiveMedia] = useState(null); // State to track the active media (image/video)
  const [isClosing, setIsClosing] = useState(false); // State to track whether the modal is closing

  // Array of imported images
  const images = [
    image1, image2, image4, image5, image6,
    image9, image10, image11, image13, image14, image16
  ];

  // Handle media click (image or video) to open the modal
  const handleMediaClick = (src) => {
    setIsClosing(false); // Ensure the closing state is reset
    setActiveMedia(src); // Open the modal with the clicked media
  };

  // Handle closing the modal
  const handleClose = () => {
    setIsClosing(true); // Start the closing animation
    setTimeout(() => {
      setActiveMedia(null); // After animation, hide modal
      setIsClosing(false); // Reset closing state
    }, 300); // Match the animation duration
  };

  return (
    <>
      <Helmet>
        <title>Gallery | 915Movement</title>
        <meta
          name="description"
          content="Explore our gallery showcasing vibrant and engaging images and videos of 915Movement's mobile party experiences."
        />
        <meta
          name="keywords"
          content="915Movement gallery, mobile party, event photos, event videos, party images, 915Movement events, El Paso, Las Cruces, party bus photos and videos"
        />
        <meta name="author" content="915Movement" />
        <meta property="og:title" content="Gallery | 915Movement" />
        <meta
          property="og:description"
          content="Check out the gallery of 915Movement, featuring exciting and lively photos and videos of our mobile party services in El Paso and Las Cruces."
        />
        <meta property="og:image" content={image1} />
        <meta property="og:url" content="https://915mvmt.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Gallery Section */}
      <div id="gallerySection" className={styles.gallerySection}>
        <h2 className={styles.galleryTitle}>Gallery</h2>
        <div className={styles.bentoGrid}>
          {/* Display all images */}
          {images.map((src, index) => (
            <button
              key={`image-${index}`}
              className={styles.bentoItem}
              onClick={() => handleMediaClick(src)} // Set active image on click
              aria-label={`Open enlarged image ${index + 1}`}
            >
              <img
                src={src}
                alt={`Gallery Image ${index + 1}`}
                className={styles.image}
                loading="lazy" // Lazy loading attribute for images
              />
            </button>
          ))}

          {/* Display all videos */}
          {videos.map((video, index) => (
            <button
              key={`video-${index}`}
              className={styles.bentoItem}
              onClick={() => handleMediaClick(video)} // Set active video on click
              aria-label={`Open video ${index + 1}`}
            >
              <iframe
                src={video}
                title={`Video ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className={styles.embedVideo}
                style={{ pointerEvents: 'none' }} // Prevent video interaction
              ></iframe>
            </button>
          ))}
        </div>

        {/* Modal for showing enlarged image or video */}
        {activeMedia && (
          <div className={styles.modalOverlay} onClick={handleClose}>
            <div
              className={`${styles.modalContent} ${
                isClosing ? styles.closing : styles.active
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <span className={styles.closeButton} onClick={handleClose}>
                &times;
              </span>
              {/* Check if activeMedia is an image or a video */}
              {activeMedia.includes("youtube") ? (
                <iframe
                  src={activeMedia}
                  title="Enlarged video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  className={styles.enlargedVideo}
                  style={{ pointerEvents: 'none' }} // Prevent video interaction
                ></iframe>
              ) : (
                <img
                  src={activeMedia}
                  alt="Enlarged view"
                  className={styles.enlargedImage}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;
